<template>
  <div>
    <div class="hidden lg:block">
      <SideBar >
        <div class="pl-14" style="height: calc(100vh - 2rem)">
          <h1>
          {{ $t('onboarding.side_panel.identity_proof.title') }}
          </h1>
          <h2>
            {{ $t('onboarding.side_panel.identity_proof.subtitle') }}
          </h2>
          <div class="indented-custom-bullet mt-3">
            <ul class="w-full ml-8 text-left">
              <li
                v-for="item in $tm('onboarding.side_panel.identity_proof.steps')"
                v-bind:key="item"
                v-html="item"
                class="pl-4 mb-4 text-grey-4 text-sm"
              ></li>
            </ul>
          </div>
          <div>
            <GreenBall
              class="steps__ball w-3/4"
              start="bottom"
              offset="-8rem"
              cutout="0"
            />

            <transition name="slide">
              <Card
              :firstName="name"
              :lastName="surname"
                class="
                  w-4/5
                  h-2/4
                  max-w-sm max-h-52
                  absolute
                  bottom-36
                  left-6
                  scale-125
                  rounded-3xl
                  transform
                "
              />
            </transition>
          </div>
        </div>
      </SideBar>
    </div>
    <div class="w-full actions lg:w-2/3 ml-auto relative md:pt-24  min-h-screen">
     <!-- back button -->
      <transition name="fade" mode="in-out">
        <div
          class="w-4/5 md:w-3/4 text-left mx-auto pt-6"
          v-if="slideConfig.includes('back')"
        >
          <div
            class="back text-left text-grey-2 flex cursor-pointer items-center"
            @click="previousSlide"
          >
            <img src="@/assets/svg/chev-left.svg" alt="" />

            <span>{{ $t('wording.back') }}</span>
          </div>
        </div>
      </transition>
      <!-- kyc upload -->
      <swiper
        class="w-full"
        :loop="false"
        @swiper="setSwiper"
        :slides-per-view="1"
        :space-between="50"
        :pagination="false"
        :allowTouchMove="false"
        :speed="400"
        :autoplay="false"
        :autoHeight="true"
        :observer="true"
        :observeParents="true"
        @slideChange="slideChange"
      >
        <swiper-slide config="later">
          <Kyc
            class="w-4/5 pr-4 mx-auto mt-0 h-full"
            @nextKyc="kycChosen"
          />
        </swiper-slide>
        <swiper-slide v-if="!isMobile()" config="back|later">
          <KycUploadType
            class="w-4/5 pr-4 mx-auto mt-0"
            @nextKyc="kycUploadTypeChosen"
          />
        </swiper-slide>
        <swiper-slide v-if="kycUploadType === 'mobile'" config="back">
          <KycUploadMobile
            class="w-4/5 pr-4 mx-auto mt-0"
            @sendSMS="sendSMS"
            @canContinue="toggleButton"
          />
        </swiper-slide>
        <swiper-slide config="back|later|next" v-slot="{ isActive }">
          <KycUpload
            class="w-4/5 pr-4 mx-auto mt-0"
            :kycType="kycType"
            :phone="phone"
            :kycUploadType="kycUploadType"
            @generatedId="generatedId"
            @canContinue="toggleButton"
            :isActive="isActive"
            :name="name"
            :surname="surname"
          />
        </swiper-slide>
      </swiper>
      <!-- submit button -->
      <transition name="fade" mode="in-out">
        <div
          class="
            w-4/5
            md:w-3/4
            text-right
            left-1/2
            -translate-x-1/2
            transform
            z-50
            absolute
            bottom-8
          "
          v-if="slideConfig.includes('next')"
        >
          <GpButton 
            class="ml-auto" 
            :disabled="!valid" 
            @click="nextSlide">
            {{ $t('wording.submit') }}
          </GpButton>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { computed, ref, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: {
    Swiper,
    SwiperSlide,
    Kyc: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/Kyc.vue')
    ),
    KycUploadType: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUploadType.vue')
    ),
    KycUploadMobile: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUploadMobile.vue')
    ),
    KycUpload: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUpload.vue')
    ),
    GpButton: defineAsyncComponent(() =>
      import('@/components/general/GpButton.vue')
    ),
    SideBar: defineAsyncComponent(() =>
      import('@/components/general/SideBar.vue')
    ),
    GreenBall: defineAsyncComponent(() =>
      import('@/components/general/GreenBall.vue')
    ),
    Card: defineAsyncComponent(() => import('@/components/general/Card.vue'))
  },
  props: {
    name: String,
    surname: String,
    redirectLink: {
      type: Boolean,
      default: false
    }
  },
  emits: ['done'],
  setup(props, { emit }) {
    const swiper = ref(null)
    const slideConfig = ref('')
    const kycType = ref(null)
    const kycUploadType = ref('upload')
    const phone = ref(null)
    const smsSent = ref(false)
    const websocketId = ref(null)
    const store = useStore()
    const submitted = ref(false)
    const data = ref(false)
    const router = useRouter()

    const valid = computed(() => {
      return !submitted.value ? ((data.value.kycFirst && data.value.kycSecond) ? true : false) : false
    })

    // Submit file to backoffice
    function submitKyc() {
      submitted.value = true
      store.dispatch('submitKyc', data.value).then(async () => {
        if(props.redirectLink) {
          data.value = false
          await store.dispatch('setToast', {
            type: 'success',
            toast: 'kyc_submitted',
            position: 'top-right'
          })
          router.push({name: 'dashboard'})
        }
      })
    }

    // If value (data returned by component), the button should be active, otherwise, there's an error in KycUpload
    function toggleButton(value) {
      data.value = value
    }

    // Set websocketId to ID generated in websocket connection
    function generatedId(value) {
      websocketId.value = value
    }

    // Function to send SMS with link for file upload (Missing twilio sandbox)
    function sendSMS(value) {
      phone.value = value
      smsSent.value = false
    }

    // Set KYC Type (National ID, Passport or Driving license)
    function kycChosen(value) {
      store.dispatch('add', { kyc_type: value })
      kycType.value = value
      nextSlide()
    }

    // Set KYC Upload Type (Mobile or Desktop)
    function kycUploadTypeChosen(value) {
      kycUploadType.value = value
      nextSlide()
    }

    // Initialize swiper instance (Add swiper component to swiper reference)
    function setSwiper(value) {
      swiper.value = value
    }

    // Go to next slide and submit KYC if case
    function nextSlide() {
      if (data.value.kycFirst) {
        submitKyc()
        // Finish
        emit('done')
      }
      swiper.value.slideTo(swiper.value.activeIndex + 1)
    }

    // Go to previous slide
    function previousSlide() {
      swiper.value.slideTo(swiper.value.activeIndex - 1)
    }

    // Triggered when a slide change occurs, get the configs of slide (if has back button, next button, etc...)
    function slideChange() {
      slideConfig.value =
        swiper.value.slides[swiper.value.activeIndex].getAttribute('config') ??
        ''
    }

    // Test if nav browser is mobile or tablet
    function isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }

    return {
      isMobile,
      setSwiper,
      slideChange,
      previousSlide,
      slideConfig,
      kycChosen,
      kycType,
      kycUploadTypeChosen,
      kycUploadType,
      sendSMS,
      toggleButton,
      nextSlide,
      phone,
      generatedId,
      submitKyc,
      valid,
      data
    }
  }
}
</script>